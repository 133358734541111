import React, { useState, useEffect, useCallback } from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../Api";

const Form = () => {
	const navigate = useNavigate();
	const [category, setCategory] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [subCategory, setSubCategory] = useState([]);
	const [selectedSubCategory, setSelectedSubCategory] = useState("");
	const [subCategoryItem, setSubCategoryItem] = useState([]);
	const [selectedItem, setSelectedItem] = useState("");
	const [hmo, setHmo] = useState([]);
	const [selectedHmo, setSelectedHmo] = useState("");
	const [file, setFile] = useState(null);
	const [isDisabled, setIsDisabled] = useState(false);
	const [buttonText, setButtonText] = useState("Upload");

	useEffect(() => {
		if (
			!selectedHmo ||
			!file ||
			!selectedItem ||
			!selectedCategory ||
			!selectedSubCategory
		) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [selectedCategory, file, selectedHmo, selectedSubCategory, selectedItem]);

	const getCategory = useCallback(async () => {
		try {
			const fetchCategory = await Axios.post(`${api.baseURL}/sectors`);
			const item = fetchCategory.data.data;
			setCategory(item);
		} catch (err) {
			console.log(err);
		}
	}, []);

	const handleSubCategory = useCallback(async (e) => {
		const curr = e.target.value;
		setSelectedCategory(curr);
		try {
			const fetch = await Axios.post(`${api.baseURL}/category/${curr}`);
			const result = fetch.data.data;
			setSubCategory(result);
		} catch (err) {
			console.log(err);
		}
	}, []);

	const handleSubCategoryItem = useCallback(
		async (e) => {
			const impt = e.target.value;
			setSelectedSubCategory(impt);
			try {
				const fetchItem = await Axios.post(
					`${api.baseURL}//${selectedCategory}/${impt}`,
				);
				const subItem = fetchItem.data.data;
				setSubCategoryItem(subItem);
			} catch (err) {
				console.log(err);
			}
		},
		[selectedCategory],
	);

	const getHmo = useCallback(async () => {
		try {
			const fetchHmo = await Axios.post(`${api.baseURL}/hmo`);
			const resultHmo = fetchHmo.data.data;
			setHmo(resultHmo);
		} catch (err) {
			console.log(err);
		}
	}, []);

	useEffect(() => {
		getCategory();
	}, [getCategory]);

	useEffect(() => {
		getHmo();
	}, [getHmo]);

	const handleHmo = (e) => {
		setSelectedHmo(e.target.value);
	};

	//file validation
	// const fileType = [
	//   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	//   "application/vnd.ms-excel",
	//   ".csv",
	// ];
	// const handleFile = (e) => {
	//   let selectedFile = e.target.files[0];
	//   if (selectedFile) {

	//     if (selectedFile && fileType.includes(selectedFile.type)) {
	//       let reader = new FileReader();
	//       let resReader = reader.readAsArrayBuffer(selectedFile);

	//       reader.onload = (e) => {
	//         setFile(resReader);
	//       };
	//     } else {
	//        setIsDisabled(true)
	//       toast.error("🤷‍♂️ Oops! only excel and csv file required", {
	//         position: toast.POSITION.TOP_CENTER,
	//         autoClose: 5000,
	//         hideProgressBar: false,
	//       });
	//     }
	//   }
	// };

	const submitForm = (e) => {
		e.preventDefault();
		setButtonText("Please wait...");
		const myHmo = JSON.parse(selectedHmo);
		const mySelectedItem = JSON.parse(selectedItem);

		var enrollData = new FormData();

		enrollData.append("email", sessionStorage.getItem("email_user"));
		enrollData.append("file", file);
		enrollData.append("hmo_id", myHmo?.id);
		enrollData.append("subcategory", selectedSubCategory);
		enrollData.append("category", selectedCategory);
		enrollData.append("hmo_name", myHmo?.name);
		enrollData.append("subcategoryitemname", mySelectedItem.name);
		enrollData.append("subcategoryitemid", mySelectedItem.id);

		Axios({
			method: "POST",
			url: `${api.baseURL}/file/upload`,
			headers: {
				"Content-Type": "multipart/form-data",
			},
			data: enrollData,
		})
			.then((response) => {
				console.log(response);
				toast.success("👍 Form Submitted Successfully!", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 8000,
					hideProgressBar: false,
					delay: 3000,
				});
				setButtonText("Upload");
				setSelectedCategory("");
				setSelectedSubCategory("");
				setSelectedItem("");
				setSelectedHmo("");
			})
			.catch(function (error) {
				console.log(error);
				setButtonText("Upload");
				setSelectedCategory("");
				setSelectedSubCategory("");
				setSelectedItem("");
				setSelectedHmo("");
				toast.error("🤷‍♂️ Form submit error. Refresh or Try again later!", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 5000,
					hideProgressBar: false,
				});
			});
	};

	return (
		<div className="form-container">
			<ToastContainer />
			<Container>
				<div
					onClick={() => navigate(-1)}
					className="back"
					style={{
						display: "flex",
						alignItems: "center",
						flexWrap: "wrap",
						cursor: "pointer",
					}}
				>
					<ArrowBackIosIcon fontSize={"small"} />
					<span>Back</span>
				</div>
				<Paper className="paper1">
					<form onSubmit={submitForm}>
						<div className="textfield">
							<TextField
								className="box"
								select
								required
								SelectProps={{
									native: true,
								}}
								sx={{
									width: "23ch",
								}}
								value={selectedCategory}
								onChange={handleSubCategory}
								error={!selectedCategory}
								helperText={!selectedCategory && "Category is Required!"}
							>
								<option value="">Select Category</option>
								{category?.map((val, id) => (
									<option key={`${val}-${id}`} value={val}>
										{val}
									</option>
								))}
							</TextField>

							<TextField
								className="box"
								select
								required
								SelectProps={{
									native: true,
								}}
								sx={{
									width: "23ch",
								}}
								value={selectedSubCategory}
								onChange={handleSubCategoryItem}
								error={!selectedSubCategory}
								helperText={!selectedSubCategory && "Subcategory is Required!"}
							>
								<option value="">Select Subcategory</option>
								{subCategory?.map((options, name) => (
									<option key={`${options}-${name}`} value={options}>
										{options}
									</option>
								))}
							</TextField>

							<TextField
								className="box"
								select
								required
								SelectProps={{
									native: true,
								}}
								sx={{
									width: "23ch",
								}}
								disabled={
									selectedSubCategory === "Plsef" ||
									selectedSubCategory === "Bhcpf" ||
									selectedSubCategory === "General"
										? true
										: false
								}
								value={selectedItem}
								onChange={(e) => setSelectedItem(e.target.value)}
								error={!selectedItem}
								helperText={!selectedItem && "Subcategory Item is Required!"}
							>
								<option value="">Select Subcategory item</option>
								{subCategoryItem?.map((opt, name) => (
									<option key={`${opt}-${name}`} value={JSON.stringify(opt)}>
										{opt.name}
									</option>
								))}
							</TextField>
						</div>
						<div className="textfield">
							<TextField
								className="box"
								name="user_email"
								label="User Email"
								InputProps={{
									readOnly: true,
								}}
								sx={{
									width: "23ch",
								}}
								value={sessionStorage.getItem("email_user")}
							/>

							<TextField
								className="box"
								select
								name="hmo"
								required
								SelectProps={{
									native: true,
								}}
								sx={{
									width: "23ch",
								}}
								value={selectedHmo}
								onChange={handleHmo}
								error={!selectedHmo}
								helperText={!selectedHmo && "HMO is Required!"}
							>
								<option>Select HMO</option>
								{hmo?.map((res) => (
									<option value={JSON.stringify(res)} key={res.id}>
										{res.name}
									</option>
								))}
							</TextField>

							<TextField
								className="box"
								required
								name="file"
								accept=".xlsx, .xls, .csv"
								type="file"
								// label="Upload File (.csv, xls, xlsx)"
								onChange={(e) => setFile(e.target.files[0])}
								error={!file}
								helperText={
									!file ? (
										"Please select a File!"
									) : (
										<span>File must be csv or excel format</span>
									)
								}
								sx={{
									width: "24ch",
								}}
							/>
						</div>

						<div className="btn">
							<div className="app-btn1">
								<Button
									className="next"
									type="submit"
									disabled={isDisabled}
									variant="contained"
									color="success"
								>
									{buttonText}
									<CheckCircleOutlineOutlinedIcon
										style={{
											fontSize: "20px",
											marginBottom: "4px",
											marginLeft: "4px",
										}}
									/>
								</Button>
							</div>
						</div>
					</form>
				</Paper>
			</Container>
		</div>
	);
};

export default Form;
