import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function Banner() {
  const navigate = useNavigate();

  return (
    <div className="banner">
      <div>
        <div>
          <img
            className="banner-img"
            src="https://cdn-icons-png.flaticon.com/512/2620/2620163.png"
            alt="logo"
            height={200}
            width={200}
          />
        </div>
        <div className="banner-below">
          <h3>Click the button below and proceed to upload Nominal Roll..</h3>

          <Button
            variant="outlined"
            onClick={() => {
              navigate("/form");
            }}
          >
            Get Started
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Banner;
