import React from 'react'
import Img from "../../src/images/PLASCHEMA.jpg"; 
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";


function Header() {
  const navigate = useNavigate();
 
  return (
    <div className="header">
     <img src={Img} alt="logo" className="logo" />
     <h1>Plateau Health Pre-Enrollment Portal</h1>
     <Avatar
        style={{ backgroundColor: "red", margin: "2rem 0.5rem", cursor:"pointer" }}
        title="Logout"
        onClick={(e) => {
          e.preventDefault();
          sessionStorage.removeItem("email_user");
          navigate("/");
        }}
      >
        <PowerSettingsNewOutlinedIcon />
      </Avatar>
    </div>
  )
}


export default Header