import React from "react";
import "./App.css";
import Header from "./components/Header";
import Banner from "./components/Banner";
import Typography from "@mui/material/Typography";
import { Route, Routes, useNavigate } from "react-router-dom";
import Form from "./components/Form";
import Login from "./components/Login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Users from "./UserLogin";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      Designed for Plateau State Contributory Health Care Management Agency by{" "}
      <a className="link" href="https://instantdeposit.africa">
        Instant Deposit Ltd.
      </a>{" "}
      All Rights Reserved.
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function App() {
  const navigate = useNavigate();

  const login = (details) => {
    const validUser = Users.find((user) => {
      const email = details.email;
      const password = details.password;
      return user.email === email && user.password === password;
    });

    if (validUser) {
      sessionStorage.setItem("email_user", details.email);
      toast.success(` Welcome ${validUser.name}`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
        hideProgressBar: false,
      });
      navigate("/");
    } else {
      toast.error("🤦‍♂️ Invalid email or password! Please check your entries.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: false,
      });
    }
  };
  // localStorage.setItem("logged", JSON.stringify(true));

  return (
    <div className="App">
      <ToastContainer />
      {!sessionStorage.getItem("email_user") ? (
        <Login login={login} />
      ) : (
        <>
          <header>
            <Header />
          </header>
          <main>
            <Routes>
              <Route exact path="/" element={<Banner />} />
              <Route exact path="/form" element={<Form />} />
            </Routes>
          </main>
          <footer>
            <Copyright sx={{ pt: 6 }} />
          </footer>
        </>
      )}
    </div>
  );
}

export default App;
